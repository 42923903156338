import * as actionTypes from 'src/actions';

const initialState = {
    globalMessage: {
        open: false,
        message: '',
        severity: ''
    },
    globalLoading: {
        open: false,
        queue: []
    }
}

const interfaceReducer = (state = initialState, action) => {
    let newState = state;

    switch (action.type) {

        case actionTypes.ADD_MESSAGE: {
            newState = {
                ...state,
                globalMessage: {
                    open: true,
                    message: action.message,
                    severity: action.severity
                }
            };
            break;
        }

        case actionTypes.REMOVE_MESSAGE: {
            newState = {
                ...state,
                globalMessage: initialState.globalMessage
            };
            break;
        }

        case actionTypes.ADD_LOADING_GLOBAL: {
            let newQueue = newState.globalLoading.queue;
            newQueue.push(action.id);
            newState = {
                ...state,
                globalLoading: {
                    open: (newQueue.length ? true : false),
                    queue: newQueue
                }
            };
            break;
        }

        case actionTypes.REMOVE_LOADING_GLOBAL: {
            let newQueue = newState.globalLoading.queue;
            for (let i = 0; i < newQueue.length; i++) {
                if (newQueue[i] === action.id) {
                    newQueue.splice(i, 1);
                }
            }
            newState = {
                ...state,
                globalLoading: {
                    open: (newQueue.length ? true : false),
                    queue: newQueue
                }
            };
            break;
        }

        default:
            return initialState;
    }

    return newState;
};

export default interfaceReducer;