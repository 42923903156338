export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

export const ADD_LOADING = 'ADD_LOADING';
export const REMOVE_LOADING = 'REMOVE_LOADING';

export const ADD_LOADING_GLOBAL = 'ADD_LOADING_GLOBAL';
export const REMOVE_LOADING_GLOBAL = 'REMOVE_LOADING_GLOBAL';

export const addMessage = (message, severity) => (dispatch) => dispatch({
        type: ADD_MESSAGE,
        message: message,
        severity: severity
});

export const removeMessage = () => (dispatch) => dispatch({
        type: REMOVE_MESSAGE,
});

export const addLoading = (id) => (dispatch) => dispatch({
        type: ADD_LOADING,
        id: id
});

export const removeLoading = (id) => (dispatch) => dispatch({
        type: REMOVE_LOADING,
        id: id
});

export const addLoadingGlobal = (id) => (dispatch) => dispatch({
        type: ADD_LOADING_GLOBAL,
        id: id
});

export const removeLoadingGlobal = (id) => (dispatch) => dispatch({
        type: REMOVE_LOADING_GLOBAL,
        id: id
});
