import mock from 'src/utils/mock';

mock.onGet('/api/search').reply(() => new Promise(((resolve) => {
        setTimeout(() => {
                resolve([200, {
                        results: [
                                {
                                        title: 'O que Algolia faz?',
                                        description: 'O Algolia consiste em duas partes: implementação de pesquisa e análise de pesquisa. Nós fornecemos ferramentas que facilitam para seus desenvolvedores...'
                                },
                                {
                                        title: 'Pesquisa como um ciclo de feedback',
                                        description: 'Para ser claro, a pesquisa não sabe a direção que sua empresa deve tomar. No entanto, pode ajudá-lo a reunir informações sobre o que seus clientes desejam...'
                                },
                                {
                                        title: 'O que o Algolia pode fazer por meus usuários?',
                                        description: 'O Algolia oferece aos seus usuários uma experiência de pesquisa rica e rápida. A interface de pesquisa do Algolia pode conter uma barra de pesquisa, filtros, rolagem infinita ...'
                                }
                        ]
                }]);
        }, 1500);
})));
