import mock from 'src/utils/mock';

mock.onGet('/api/management/content/pages').reply(200, {
    content: {
        title: 'Home',
        url: '/',
        blocks: [
            {
                type: 'fullBanner',
                content: [
                    {
                        imageDesktop: '',
                        imageTablet: '',
                        imageMobile: '',
                        link: '',
                    },
                    {
                        imageDesktop: '',
                        imageTablet: '',
                        imageMobile: '',
                        link: '',
                    }
                ]
            },
    
            {
                type: 'text',
                content: ""
            },
    
            {
                type: 'productsGrid',
                title: 'Title',
                content: [
                    {
                        product: '123',
                        sortOrder: 1
                    }
                ]
            },
    
            {
                type: 'mosaic',
                content: [
                    {
                        imageDesktop: '',
                        imageTablet: '',
                        imageMobile: '',
                        link: '',
                    },
                    {
                        imageDesktop: '',
                        imageTablet: '',
                        imageMobile: '',
                        link: '',
                    }
                ]
            }
    
        ]
    }
});

mock.onGet('/api/management/content/pages/1').reply(200, {
    content: {
        title: 'Home',
        url: '/',
        blocks: [
            {
                type: 'fullBanner',
                content: [
                    {
                        imageDesktop: '',
                        imageTablet: '',
                        imageMobile: '',
                        link: '',
                    },
                    {
                        imageDesktop: '',
                        imageTablet: '',
                        imageMobile: '',
                        link: '',
                    }
                ]
            },
    
            {
                type: 'text',
                content: ""
            },
    
            {
                type: 'productsGrid',
                title: 'Title',
                content: [
                    {
                        product: '123',
                        sortOrder: 1
                    }
                ]
            },
    
            {
                type: 'mosaic',
                content: [
                    {
                        imageDesktop: '',
                        imageTablet: '',
                        imageMobile: '',
                        link: '',
                    },
                    {
                        imageDesktop: '',
                        imageTablet: '',
                        imageMobile: '',
                        link: '',
                    }
                ]
            }
    
        ]
    }
});